require("./plugins/bootstrap.js");
require("./plugins/bootstrap-datepicker.js");
require("./plugins/magnific-popup.js");

require("./modules/forms.js");
require("./modules/reviews.js");
require("./modules/fastbooker.js");
require("./modules/slides.js");
require("./modules/mobile-nav.js");

$(".js-gallery").magnificPopup({
  delegate: "a",
  type: "image",
  gallery: {
    enabled: true
  }
});
